// 引入 axios
import axios from "axios";



// let base = 'http://192.168.1.9:8087';
let base = 'https://hnect.net/portal-admin';

//传送json格式的get请求
export const getRequest=(url,params)=>{
    return axios({
        method:'get',
        url:`${base}${url}`,
        data: params,
    })
}
